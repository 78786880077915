import { extend, localize } from 'vee-validate'
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  min_value as rule_min_value,
  max_value as rule_max_value,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
} from 'vee-validate/dist/rules'
import en from 'vee-validate/dist/locale/en.json'
import fr from 'vee-validate/dist/locale/fr.json'
import i18n from '@/libs/i18n'

// eslint-disable-next-line object-curly-newline
import {
  validatorPositive,
  validatorUrlValidator,
  validatorPassword,
  validatePhone,
  validatorNic,
  validatorCreditCard,
  validatorPhone,
  validatorDecimal,
  validatorSocialSecurityNumber
} from './validators'

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', rule_required)

export const email = extend('email', rule_email)

export const min = extend('min', rule_min)

export const min_value = extend('min_value', rule_min_value)

export const max_value = extend('max_value', rule_max_value)

export const confirmed = extend('confirmed', rule_confirmed)

export const regex = extend('regex', rule_regex)

export const between = extend('between', rule_between)

export const alpha = extend('alpha', rule_alpha)

export const integer = extend('integer', rule_integer)

export const digits = extend('digits', rule_digits)

export const alphaDash = extend('alpha-dash', rule_alpha_dash)

export const alphaNum = extend('alpha-num', rule_alpha_num)

export const length = extend('length', rule_length)

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
})

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
})

export const password = extend('password', {
  validate: validatorPassword,
  // message: 'Your {_field_} must contain at least one uppercase, one lowercase, one special character and one digit',
  message: i18n.t('NewPasswordMessage', {field: '{_field_}'}),
})

export const phone = extend('phone', {
  validate: validatorPhone,
  message: i18n.t('It is not valid phone number!'),
})

export const nic = extend('nic', {
  validate: validatorNic,
  message: i18n.t('It is not valid nic!'),
})

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
})

export const decimal = extend('decimal', {
  validate: validatorDecimal,
  message: 'Number is invalid',
})

export const socialSecurityNumber = extend('socialSecurityNumber', {
  validate: validatorSocialSecurityNumber,
  message: i18n.t('It is not valid social security number'),
})

// Install English and French localizations.
if (i18n.locale == 'en') {
  localize('en', en)
} else {
  localize('fr', fr)
}

// localize({
//   // en: {
//   //   messages: en.messages,
//   //   names: {
//   //     email: 'Email',
//   //     password: 'Password',
//   //   },
//   //   fields: {
//   //     password: {
//   //       min: '{_field_} is too short, you want to get hacked?',
//   //     },
//   //   },
//   // },
//   fr: {
//     messages: fr.messages,
//     names: {
//       email: 'Email',
//       password: 'Password',
//     },
//     fields: {
//       password: {
//         min: '{_field_} est trop court, tu veux te faire pirater ?',
//       },
//     },
//   },
// })